import React from 'react';

import Utils from 'library/Utils';
import APIRequest from 'library/Request';

import { Button } from 'components/Button';
import { API_URL } from 'library/constants';
import IndyLink from 'components/IndyLink';
import Checkbox from 'components/Checkbox';

class DownloadBrochureForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            "name": "",
            "email": "",
            "company": "",
            "readTerms": false,
            "validateError":""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        if (!this.props.hasOwnProperty("pagePos")){
            throw Error('Page position required for download brochure form');
        }

        this.getBadInput = this.getBadInput.bind(this);
    }

    getBadInput(state) {
        if (!state) {
            state = this.state;
        }

        if (state.hasOwnProperty("name") && state.name === ""){
            return "name";
        }
        if (state.hasOwnProperty("company") && state.company === ""){
            //return "company";
        }
        if (state.hasOwnProperty("email") && !Utils.validateEmail(state.email)){
            return "email";
        }
        if(!state.readTerms) {
            return "readTerms";
        }

        return "";
    }

    handleChange = event => {
        let state = this.state;
        state[event.target.name] = event.target.value;

        if (event.target.name === this.state.validateError){
            state.validateError = this.getBadInput(state);
        }

        this.setState(state);
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({validateError: this.getBadInput()});
        return;
    }

    render() {
        let downloadURL = "";
        if (this.getBadInput() == "" && typeof document !== `undefined`){
            downloadURL = API_URL + "getBrochure/" + APIRequest.formGetVars({
                navLoc: "/library-and-share",
                action: "getBrochure_"+this.props.pagePos,
                referrer: document.referrer,
                name: this.state.name,
                email: this.state.email,
                company: this.state.company
            });
        }

        let nameClass = "";
        let emailClass = "";
        let companyClass = "";

        switch(this.state.validateError){
            case "":
            case "readTerms":
                break;
            case "name":
                nameClass = " invalid"; break;
            case "email":
                emailClass = " invalid"; break;
            case "company":
                companyClass = " invalid"; break;
            default:
                throw new Error('Validation class unknown');
        }

        return (
            <form className="bannerForm">
                <h2>DOWNLOAD OUR SERVICES BROCHURE</h2>
                <input  name="name"
                        placeholder="Full Name..."
                        onChange={this.handleChange}
                        value={this.state.name}
                        className={ "formInput" + nameClass }
                />
                <input  name="company"
                        placeholder="Company..."
                        onChange={this.handleChange}
                        value={this.state.company}
                        className={ "formInput" + companyClass }
                />
                <input
                        name="email"
                        type="email"
                        placeholder="Email..."
                        onChange={this.handleChange}
                        value={this.state.email}
                        className={ "formInput" + emailClass }
                />
                <div>
                    <Checkbox
                        label={<span >I have read and accept Mashoom's <IndyLink adr="/terms-and-conditions/">terms and conditions</IndyLink> and <IndyLink adr="/privacy-policy/">privacy policy</IndyLink></span>}
                        isChecked={this.state.readTerms}
                        changeHandler={()=>{this.setState({readTerms: !this.state.readTerms, validateError: ''})}}
                        highlight={(this.state.validateError == "readTerms")}
                    />
                </div>
                {downloadURL
                ? <Button className="form-button" position="center" type="Dwnld" adr={downloadURL} title="Download" />
                : <Button className="form-button" position="center" type="Dwnld" onClick={this.handleSubmit} title="Download" /> 
                }
            </form>
        );
    }
}
 
export default DownloadBrochureForm;