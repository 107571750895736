import React from 'react';

import './style.scss';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "isChecked": this.props.isChecked } 
        
        this.handleCheck = this.handleCheck.bind(this);
    }

    handleCheck(e){
        if (this.props.changeHandler){
            this.props.changeHandler(e);
        }else{
            this.setState({"isChecked": e.target.checked});
        }
    }

    render() { 
        let isChecked = this.state.isChecked;
        if (this.props.hasOwnProperty("isChecked")){
            isChecked = this.props.isChecked;
        }

        let id;
        if (!this.props.id){
            id = "chkbx_" + Math.floor(Math.random() * 10000);
        }else{
            id = this.props.id;
        }

        let wrapClass = "mshm_checkbox_wrap";
        if (this.props.className){
            wrapClass += " " + this.props.className;
        }

        let labelClass = "mshm_checkbox_label";
        if (this.props.highlight){
            labelClass += " highlight";
        }

        return (
            <span className={wrapClass}>
                <input type="checkbox" 
                            id={id}
                            className="mshm_checkbox"
                            name={this.props.name}
                            checked={isChecked} 
                            onChange={this.handleCheck} />
                <label htmlFor={id} className={labelClass}>{this.props.label}
                </label>
            </span>
        );
    }
}
 
export default Checkbox;