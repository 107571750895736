import React from 'react';
import './style.scss';

export function PageSection({id, title, subtitle="", children, text=""}){
    return (
        <div id={id} className={"landingSection " + id}>
            <div className="titleSection">
                <h2>{title.toUpperCase()}</h2>
                {subtitle ? <h3>{subtitle}</h3> : null}
                {text ? <p>{text}</p> : null}
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    );
}
