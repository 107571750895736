import React from 'react';
import Calendar from 'react-calendar';
import './style.scss';
import Select from 'components/Select';

// Allows standardization of date time picker
export default function DateTimePicker({className, onChange, value, minDetail, disableWeekends, startHour, endHour, minInterval, fromNow, highlight, lightTheme=true}){
    let tileDisabled;
    if (disableWeekends){
        tileDisabled = ({activeStartDate, date, view }) => (date.getDay() === 0 || date.getDay() === 6);
    }else{
        tileDisabled = function(){return false;}
    }

    let initDate = function(){
        let value = new Date();
        value.setMinutes(0);
        value.setSeconds(0);

        if (value.getHours() < startHour){
            value.setHours(startHour);
        }
        if (value.getHours() > endHour){
            value.setHours(endHour);
        }

        return value;
    };

    let dateOnChange = function(value, onChange, newValue){
        if (value === null){
            value = initDate();
        }

        value.setFullYear(newValue.getFullYear(), newValue.getMonth(), newValue.getDate());
        onChange(value);
    }.bind(null, value, onChange);

    let hourOnChange = function(value, onChange, e){
        if (value === null){
            value = initDate();
        }

        value.setHours(e.target.value);
        onChange(value);
    }.bind(null, value, onChange);

    let minuteOnChange = function(value, onChange, e){
        if (value === null){
            value = initDate();
        }

        value.setMinutes(e.target.value);
        onChange(value);
    }.bind(null, value, onChange);

    let hourOptions = {};
    for (let hour = startHour; hour <= endHour; hour++) {
        hourOptions[hour] = hour.toString().padStart(2, '0');
    }

    let minuteOptions = [];
    for (let minutes = 0; minutes < 60; minutes = parseInt(minutes) + parseInt(minInterval)) {
        minuteOptions[minutes] = minutes.toString().padStart(2, '0');
    }

    const wrapClass = `DateTimePickerWrap ${className || ''} ${lightTheme && 'calendarLight'} ${highlight && 'highlight'}`;

    let hourVal = null;
    let minuteVal = null;
    if (value !== null){
        hourVal = value.getHours();
        minuteVal = value.getMinutes();
    }else{
        let now = initDate();
        hourVal = now.getHours();
        minuteVal = now.getMinutes();
    }


    return(
        <div className={wrapClass}>
            <Calendar 
                onChange={dateOnChange}
                value={value} 
                minDetail={minDetail}
                tileDisabled={tileDisabled}
                minDate={fromNow ? new Date() : null}/>
            <div className='DTTimePicker'>
                <Select name="hour" value={hourVal} options={hourOptions} onChange={hourOnChange} size="big" lightTheme={lightTheme}/>
                <p>:</p>
                <Select name="minutes" value={minuteVal} options={minuteOptions} onChange={minuteOnChange} size="big" lightTheme={lightTheme}/>
            </div>
        </div>
    );
}

DateTimePicker.defaultProps = {
    'minDetail': "month",
    'disableWeekends': false,
    'startHour': 9,
    'endHour': 17,
    'minInterval': 60,
    'fromNow': true,
    'className': ""
}