import React from 'react';

import Utils from 'library/Utils';
import APIRequest from 'library/Request';

import { Button } from 'components/Button';
import Checkbox from 'components/Checkbox';
import DateTimePicker from 'components/DateTimePicker';
import IndyLink from 'components/IndyLink';
import Lightbox from 'components/Lightbox';

class RequestMeetingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            "name": "",
            "email": "",
            "company": "",
            "phone": "",
            "message": "",
            "dateTime": null,
            "schedule": false,
            "readTerms": false,
            "validateError": "",
            "submitDisable": false,
            "showConfirm": false
        }
        this.getBadInput = this.getBadInput.bind(this);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getBadInput(state) {
        if (!state) {
            state = this.state;
        }

        if (state.name === ""){
            return "name";
        }
        if (state.company === ""){
           // return "company";
        }
        if (!Utils.validateEmail(state.email)){
            return "email";
        }
        if (!Utils.validatePhone(state.phone)){
            return "phone";
        }
        if (state.message === ""){
            return "message";
        }
        if (state.schedule){
            if (!(state.dateTime instanceof Date)){
                return "time";
            }

            let now = new Date();
            if (state.dateTime < now){
                return "time";
            }
        }
        if (!state.readTerms){
            return "readTerms";
        }

        return "";
    }

    handleChange = event => {
        let state = this.state;
        state[event.target.name] = event.target.value;

        if (event.target.name === this.state.validateError){
            state.validateError = this.getBadInput(state);
        }

        this.setState(state);
    }

    handleDateChange = value => {
        let state = this.state;
        state["dateTime"] = value;

        if ("time" === this.state.validateError){
            state.validateError = this.getBadInput(state);
        }

        this.setState(state);
    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.submitDisable) {
            return;
        }

        let valError = this.getBadInput();
        this.setState({validateError: valError});

        if (valError != ""){
            return;
        }

        this.setState({submitDisable: true});

        let meetingTime = null
        if (this.state.schedule){
            meetingTime = Math.floor(this.state.dateTime.getTime() / 1000)
        }

        // Send request
        let req = new APIRequest("contact/", {
            navLoc: this.props.navLoc,
            action: "contact",
            referrer: document.referrer,
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            phone: this.state.phone,
            meetingTime: meetingTime
        },{
            requestType: "POST"
        });
    
        req.makeRequest().then(function(){
            this.setState({showConfirm: true, submitDisable: false});
        }.bind(this));
    }

    render() {
        let nameClass = "";
        let emailClass = "";
        let companyClass = "";
        let messageClass = "";
        let phoneClass = "";

        switch(this.state.validateError){
            case "":
            case "readTerms":
            case "time":
                break;
            case "name":
                nameClass = "invalid"; break;
            case "email":
                emailClass = "invalid"; break;
            case "company":
                companyClass = "invalid"; break;
            case "message":
                messageClass = "invalid"; break;
            case "phone":
                phoneClass = "invalid"; break;
            default:
                throw new Error('Validation class unknown');
        }

        return (<>
            <form className="bannerForm">
                <h2>CONTACT US</h2>
                <input  name="name"
                        placeholder="Full Name..."
                        onChange={this.handleChange}
                        value={this.state.name}
                        className={ "formInput " + nameClass }
                />
                <input  name="company"
                        placeholder="Company..."
                        onChange={this.handleChange}
                        value={this.state.company}
                        className={ "formInput " + companyClass }
                />
                <input
                        name="email"
                        type="email"
                        placeholder="Email..."
                        onChange={this.handleChange}
                        value={this.state.email}
                        className={ "formInput " + emailClass }
                />
                <input
                        name="phone"
                        type="phone"
                        placeholder="Phone..."
                        onChange={this.handleChange}
                        value={this.state.phone}
                        className={ "formInput " + phoneClass }
                />
                <textarea
                        name="message"
                        placeholder="Message..."
                        onChange={this.handleChange}
                        value={this.state.message}
                        className={ "formInput " + messageClass }
                />
                <Checkbox
                    className="meetingSelCheck"
                    label="Do you want to schedule a meeting?"
                    isChecked={this.state.schedule}
                    changeHandler={()=>{this.setState({schedule: !this.state.schedule})}}
                />
                {this.state.schedule &&
                    <DateTimePicker
                        className="ReqMeetingTimeSel"
                        onChange={this.handleDateChange}
                        value={this.state.dateTime}
                        disableWeekends={true}
                        minInterval="15"
                        highlight={(this.state.validateError == "time")}
                    />
                }
                <Checkbox
                        label={<span >I have read and accept Mashoom's <IndyLink adr="/terms-and-conditions/">terms and conditions</IndyLink> and <IndyLink adr="/privacy-policy/">privacy policy</IndyLink></span>}
                        isChecked={this.state.readTerms}
                        changeHandler={()=>{this.setState({readTerms: !this.state.readTerms, validateError: ''})}}
                        highlight={(this.state.validateError == "readTerms")}
                    />
                <Button className="form-button" position="center" type="CnfmEmailSend" title="Send" onClick={this.handleSubmit} disable={this.state.submitDisable} />
            </form>

            <Lightbox show={this.state.showConfirm}
                        onClose={() => { this.setState({ showConfirm: false }) }}
                        size="small">
                <p style={{"textAlign": "center", "marginBottom": "1em"}}>Thank you for getting in contact, one of the team will respond shortly.</p>
                <Button position="center" type="IntLink" title="Go to home page" adr="/" />
            </Lightbox>
        </>);
    }
}
 
export default RequestMeetingForm;