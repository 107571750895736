import React from 'react';
import Img from "gatsby-image";
import './style.scss';

import BackgroundImage from 'gatsby-background-image'

export function BannerSection({id, lhs, rhs, image, alt = "", className = "", isGraphQL = true}) {
    const children = (
                        <div className="bannerContent">
                          <div className="bannerContentLeft"> {lhs} </div>
                          <div className="bannerContentRight"> {rhs} </div>
                        </div>
                      );
    if(isGraphQL) {
        const imageData = image.childImageSharp.fluid;
        return (
            <BackgroundImage
              id={id}
              Tag="section"
              className="bannerContainer"
              fluid={imageData}
            >
              {children}
            </BackgroundImage>
        );
    }
    const imageData = image.childImageSharp.fixed;
    return(
        <div id={id} className="bannerContainer">
            <Img fixed={imageData} alt={alt ? alt : "Unknown"} />
            {children}
        </div>
    );
}
