import React from 'react';

import style from './style.module.scss';

export default function Select({options, value, name, onChange, size, lightTheme =  false}){
    if (!name){
        throw Error('Select input must have name');
    }

    let optionsSects = [];
    for (const key in options) {
        if (options.hasOwnProperty(key)) {
            optionsSects.push(
                <option key={key} value={key}>{options[key]}</option>
            );
        }
    }

    const className = lightTheme ? style.selectLight : style.select;

    return (
        <select className={`${className} ${style["size_"+size]}`} name={name} value={value} onChange={(e) => onChange(e)}>
            {optionsSects}
        </select>
    );
}

// Set default props
Select.defaultProps = {
    options: {},
    value: null,
    size: "standard"
};