import React from 'react';
import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image'

import { BASE_URL } from 'library/constants.js';

import { PageSection } from 'components/PageSection';
import { ModuleIcon } from 'components/ModuleIcon';
import { WorkedWith } from 'components/WorkedWith';
import { BannerSection } from 'components/BannerSection';
import { Button } from 'components/Button';
import RequestMeetingForm from 'components/RequestMeetingForm';
import DownloadBrochureForm from 'components/DownloadBrochureForm';
import IndyLink from 'components/IndyLink';

import Logo from 'media/shared/mashoomLogos/LogoOnDark.inline.svg';
import './style.scss';
import Lightbox from 'components/Lightbox';
import ViewTrack from 'library/ViewTrack';

export const menuItems = {
    "Services": "ServiceSection",
    "About Us": "AboutUsSection",
    "Our Clients": "PartnersSection",
    "Contact": "ContactSection"
};

export function MainBanner({ image }) {
    return (
        <BannerSection
            id="MainBanner"
            lhs={
                <>
                    <h1>Online data storage and sharing</h1>
                    <h2>Pay per use model</h2>
                    <p>Store files in the cloud, as you would a file server. Send, distribute and receive files of any size.</p>
                </>
            }
            rhs={<DownloadBrochureForm pagePos="TopBanner" />}
            image={image}
        />
    );
}

export function AboutUsSection() {
    return (
        <PageSection
            id={menuItems["About Us"]}
            title="About Us"
            subtitle="We started out making CAD management software for our university racing team. From there we have worked with a range of industry partners to take our platform further. We now offer a toolbox of solutions for teams and business."
            children={
                <>
                    <ModuleIcon name="mashoom" containerClass="moduleIcon" />
                    <div className="borderBox">
                        <h3>Privacy, security and continuity</h3>
                        <p>We pride ourselves in how we handle our client’s data. At all times your data is your own; no funny business.</p>
                        <p>We provide a service for a price; there is no secondary income through other means like selling data, social engagement or advertisement.</p>
                    </div>
                    <div className="tickList">
                        <h3>What we offer</h3>
                        <ul>
                            <li>Online solution; no installation, access anywhere</li>
                            <li>Pay per use, not pay per user</li>
                            <li>Secure and private</li>
                            <li>“Enterprise class”; can it handle lots of people effectively</li>
                            <li>Tracking / monitoring / logging for compliance</li>
                            <li>Permissions to control who sees what</li>
                            <li>Customized branding</li>
                            <li>Easy to use, even for non-techy people</li>
                        </ul>
                    </div>
                </>
            }
        />
    );
}

export function PartnersSection() {
    return (
        <PageSection
            id={menuItems["Our Clients"]}
            title="We have worked with"
            subtitle="All of our modules have been designed alongside industry partners who continue to use our solutions."
            children={
                <>
                    <div className="partners"> <WorkedWith /> </div>
                    <div className="testimony">
                        <p><b>Dean Smith</b>, partner at WT Partnership</p>
                        <div className="testimonyText">
                            <p><i>“WT Partnership appointed Mashoom to develop a robust document and Project administration system that we could utilise for commissions with both public and private sector clients. Whilst the system was based on an existing platform run by Mashoom we were delighted with
                                    the modifications made to make it better suited to our requirements and to include WTP’s branding, which is enabling us to use the system extensively throughout the UK and increasingly internationally.</i></p> 
                            <p><i>In addition, whilst there have been very few operational teething problems with the system the responsiveness and speed of resolution by Mashoom has been excellent.</i></p>
                            <p><b><i>We would highly recommend the use of Mashoom for any organisation that is looking for an effective and intuitive secure document management system”</i></b></p>
                        </div>
                    </div>
                </>}
        />
    );
}

export class TopicsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: "",
            showBrochureForm: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick = (id) => {
        if (id == "Share"){
            ViewTrack.send("/library-and-share", "TopicOpen_Library");
        }else{
            ViewTrack.send("/library-and-share", "TopicOpen_Share");
        }

        this.setState({
            collapse: id
        });
    }

    render() {
        return (
            <PageSection
                id={menuItems["Services"]}
                title="Services"
                subtitle="We provide all the tools you need to share documents, both within your organization and to your clients."
                text="Our platform splits into Modules. Each Module is independent from one another but automatically integrate when used together."
                children={
                    <>
                        {!this.state.collapse || this.state.collapse == "Library"
                            ? <TopicIcon 
                                iconName="Library" 
                                title="The Library Module" 
                                onClick={() => this.onClick("Share")} 
                                isCollapse={this.state.collapse == "Library"}
                                image={this.props.libraryImage.childImageSharp.fixed}
                            />
                            :
                            <div className="moduleLink Library moduleData">
                                <div>
                                    <ModuleIcon name="Library" containerClass="moduleIcon" />
                                    <h1>The Library Module</h1>
                                </div>
                                <div className={"dataContent " + (this.state.showBrochureForm ? "bannerFormOverlay" : "")}>
                                    <div>
                                        <p>The Library module provides a powerful, scalable and simple solution to your document and data storage.</p>
                                        <p>Store all your files in the cloud, as you would a file server. A powerful permissions system allows you to use this as your sole file storage, either for you, or your corporation.</p>
                                        <p>A simple folder structure allows you to store files like you are used to. There is no limits to how many folder levels you can have and you can download a whole folder in one go as a .zip.</p>
                                        <p>"Projects" allow clutter management and simple filters and search functions allow you to find what you are after as quickly as possible.</p>
                                        <ul className='highlightPoints'>
                                            <li>Simple, effective versioning</li>
                                            <li>Migrate all your files quickly</li>
                                            <li>Outrageously powerful permissions</li>
                                            <li>Complete traceability</li>
                                        </ul>
                                        <Button className="form-button" position="center" type="Dwnld" onClick={() => this.setState({ showBrochureForm: !this.state.showBrochureForm })} title="Download More Information" />
                                    </div>
                                </div>
                                <Img fixed={this.props.libraryImage.childImageSharp.fixed}
                                    alt="Cartoon of people sharing data in the cloud"
                                    className="peopleImg"
                                />
                            </div>
                        }
                        {!this.state.collapse || this.state.collapse == "Share"
                            ? <TopicIcon 
                                iconName="Share" 
                                title="The Share Module" 
                                onClick={() => this.onClick("Library")} 
                                isCollapse={this.state.collapse == "Share"} 
                                image={this.props.shareImage.childImageSharp.fixed}
                            />
                            :
                            <div className="moduleLink Share moduleData">
                                <div>
                                    <ModuleIcon name="Share" containerClass="moduleIcon" />
                                    <h1>The Share Module</h1>
                                </div>
                                <div className="dataContent">
                                    <p>If you want to share your data with others, this is the module for you.</p>
                                    <p>This module enables "external users", who by default don't have access to anything on your account.</p>
                                    <p>We then provide a simple way to send and receive files with people via email, with no sign-up required by them. This means this can provide a complete alternative to email attachments.</p>
                                    <p>Looking to protect yourself or your team from cyber threats as they send and receive files? Implement this module with virus scanning and your problem is solved.</p>
                                    <ul className='highlightPoints'>
                                        <li>Enables “external users”</li>
                                        <li>Send files via email</li>
                                        <li>Receive files from anyone</li>
                                    </ul>
                                    <Button className="form-button" position="center" type="Dwnld" onClick={() => this.setState({ showBrochureForm: !this.state.showBrochureForm })} title="Download More Information" />
                                </div>
                                <Img fixed={this.props.shareImage.childImageSharp.fixed}
                                    alt="Cartoon of people using the cloud"
                                    className="peopleImg"
                                />
                            </div>
                        }
                        <Lightbox show={this.state.showBrochureForm}
                            onClose={() => { this.setState({ showBrochureForm: false }) }}
                            noPadding
                            noBorder
                            size="small">
                            <DownloadBrochureForm pagePos="LibrarySection" />
                        </Lightbox>
                    </>
                }
            />
        );
    }
}

function TopicIcon({ iconName, title, onClick, image, isCollapse = false }) {
    let classNameStr = "moduleLink " + iconName + (isCollapse ? " collapse" : "");

    return (
        <div className={classNameStr} onClick={onClick}>
            <BackgroundImage 
                fixed={image}
                className="moduleLinkInner"
                style={{"backgroundSize": (isCollapse ? "cover" : "contain")}}
            >
                <div className='TopicIconCont'>
                    <ModuleIcon name={iconName} containerClass="moduleIcon" />
                    <h1>{title}</h1>
                </div>
            </BackgroundImage>
        </div>
    );
}
export function ContactBanner({ image }) {
    return (
        <BannerSection
            id="ContactBanner"
            lhs={
                <>
                    <h1>Hello,</h1>
                    <h2>If you would like to learn more or ask us something, please fill in this form and we will contact you.</h2>
                    <p>We would love to have a meeting.</p>
                    <IndyLink adr={BASE_URL}>
                        <Logo alt="Mashoom logo" />
                    </IndyLink>
                </>
            }
            rhs={<RequestMeetingForm navLoc="/library-and-share"/>}
            image={image}
        />
    );
}
export function ContactBannerSection({ image }) {
    return (
        <PageSection
            id={menuItems["Contact"]}
            title="Contact"
            children={<ContactBanner image={image} />}
        />
    );
}