import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import {
    menuItems,
    MainBanner,
    AboutUsSection,
    PartnersSection,
    TopicsSection,
    ContactBannerSection
} from 'components/LandingPageSection';
import PageWrap from 'components/PageWrap';
import NavMenuLandingPage from 'components/NavMenuLandingPage';

export default function LibraryAndSharePage() {
    let adr = "/library-and-share";
    let images = getImages();

    return (
        <PageWrap
            title="Mashoom"
            path={adr}
            isLandingPage={true}
        >
            <NavMenuLandingPage menuItems={menuItems} />
            <MainBanner image={images.topBanner}/>
            <TopicsSection libraryImage={images.libraryPeople} shareImage={images.sharePeople}/>
            <AboutUsSection />
            <PartnersSection />
            <ContactBannerSection image={images.contactBanner}/>
        </PageWrap>
    );
}

function getImages() {
    return useStaticQuery(graphql`
        query LibAndShareImages {
            topBanner: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "LandingPage/cyber_padlock.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            contactBanner: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "LandingPage/information_connections.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            libraryPeople: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "LandingPage/people-sharing-data.png"}) {
                childImageSharp {
                    fixed(quality: 90, height: 600) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            },
            sharePeople: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "LandingPage/people-using-cloud.png"}) {
                childImageSharp {
                    fixed(quality: 90, height: 600) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `);
}