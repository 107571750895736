import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import IndyLink from 'components/IndyLink';

import {BASE_URL} from 'library/constants.js';
import Logo from 'media/shared/mashoomLogos/LogoOnLight.inline.svg';

class NavMenuLandingPage extends React.Component {

    renderMenuItem(menuTitle, index, sectionTitle) {
        return (
            <li key={menuTitle} className="">
                <IndyLink className="" adr={"#"+sectionTitle}>
                    {menuTitle}
                </IndyLink>
            </li>
        );
    }

    render() {
        const menuItems = this.props.menuItems;
        return (
            <header id="landingTitleBar">
                    <div id="headerContWrap">
                        <div id="logoWrap">
                            <IndyLink adr={BASE_URL}>
                                <Logo alt="Mashoom logo" />
                            </IndyLink>
                        </div>
                        <nav id="headerNav" className="navBar">
                            <ul>
                                {Object.keys(menuItems).map((item, index) => this.renderMenuItem(item, index, menuItems[item] ))}
                            </ul>
                        </nav>
                    </div>
            </header>
        );
    }
}

NavMenuLandingPage.propTypes = {
    menuItems: PropTypes.object,
};

export default NavMenuLandingPage;